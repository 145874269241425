import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '随幻空间场景'
    },
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/index.vue'),
    children: [
      {
        path: '/home',
        redirect: '/home/homepage'
      },
      {
        path: '/home/homepage',
        name: 'home',
        meta: {
          title: '随幻空间场景'
        },
        component: () => import(/* webpackChunkName: "home" */ '@/views/components/pc/homepage.vue'),
      },
      {
        path: '/home/selectedpage',
        name: 'home',
        meta: {
          title: '随幻空间场景'
        },
        component: () => import(/* webpackChunkName: "home" */ '@/views/components/pc/selectedpage.vue'),
      },
      {
        path: '/home/mypage',
        name: 'home',
        meta: {
          title: '随幻空间场景'
        },
        component: () => import(/* webpackChunkName: "home" */ '@/views/components/pc/mypage.vue'),
      },
    ]
  },
  {
    path: '/mobile',
    name: 'mobile',
    meta: {
      title: '随幻空间场景'
    },
    component: () => import(/* webpackChunkName: "home" */ '@/views/mobile/index.vue'),
    children: [
      {
        path: '/mobile',
        redirect: '/mobile/homepage'
      },
      {
        path: '/mobile/homepage',
        name: 'mobile',
        meta: {
          title: '随幻空间场景',
          keepAlive: true
        },
        component: () => import(/* webpackChunkName: "home" */ '@/views/components/mobile/homepage.vue'),
      },
      {
        path: '/mobile/selectedpage',
        name: 'mobile',
        meta: {
          title: '随幻空间场景',
          keepAlive: true,
        },
        component: () => import(/* webpackChunkName: "home" */ '@/views/components/mobile/selectedpage.vue'),
      },
      {
        path: '/mobile/customerpage',
        name: 'mobile',
        meta: {
          title: '随幻空间场景',
        },
        component: () => import(/* webpackChunkName: "home" */ '@/views/components/mobile/customerpage.vue'),
      },
      {
        path: '/mobile/mypage',
        name: 'mobile',
        meta: {
          title: '随幻空间场景',

        },
        component: () => import(/* webpackChunkName: "home" */ '@/views/components/mobile/mypage.vue'),
      },
      {
        path: '/mobile/collectionexclusive',
        name: 'mobile',
        meta: {
          title: '随幻空间场景',
          keepAlive: true,
        },
        component: () => import(/* webpackChunkName: "home" */ '@/views/components/mobile/collectionexclusive.vue'),
      },
      {
        path: '/mobile/login',
        name: 'mobile',
        meta: {
          title: '随幻空间场景'
        },
        component: () => import(/* webpackChunkName: "home" */ '@/views/components/mobile/login.vue'),
      },
      {
        path: '/mobile/detail',
        name: 'mobile',
        meta: {
          title: '随幻空间场景',

        },
        component: () => import(/* webpackChunkName: "home" */ '@/views/components/mobile/detail.vue'),
      },
    ]
  }
]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  // console.log(to.path, from.path);
  // if (to.path == '/mobile/detail' && from.path == '/mobile/homepage') {
  //   from.meta.keepAlive = true; // 让 列表⻚ 缓存，即不刷新
  // } else {
  //   from.meta.keepAlive = false; // 让 列表⻚ 即不缓存，刷新
  // }
  // if (to.path == '/mobile/homepage' && from.path == '/mobile/detail') {
  //   to.meta.keepAlive = true;
  // } else {
  //   to.meta.keepAlive = false;
  // }
  next()
});


export default router
