
const state = {
    tabActivevuex: '首页',
    mypagetypevuex: '我的收藏',
    mobileDetailItemvuex: {},
}

const mutations = {
    CHANGE_STORAGE_SETTING: (state, { key, value }) => {
        // eslint-disable-next-line no-prototype-builtins
        if (state.hasOwnProperty(key)) {
            state[key] = value
        }
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
