<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>

export default {
  data() {
    return {

    }
  },
  watch: {
    '$route': {
      handler(val) {
        console.log(val.name);
        if (this.$isMobile) {
          if (val.name == 'home') {
            this.$router.push({
              path: '/mobile'
            })
          }

        } else {
          if (val.name == 'mobile') {
            this.$router.push({
              path: '/home'
            })
          }
        }
      },
      deep: true,
      immediate: true
    },
  },
  created() {
    // console.log(this.$route);
    // if (this.$isMobile) {
    //   this.$router.push({
    //     path: '/mobile'
    //   })
    // } else {
    //   this.$router.push({
    //     path: '/home'
    //   })
    // }
  }
}
</script>

<style lang="less">
#app {
  width: 100%;
  height: 100%;
}
</style>
