import Vue from 'vue'
import Vuex from 'vuex'
import global from './modules/global'
import storage from './modules/storage'
import createPersistedState from 'vuex-persistedstate' // vuex 持久化
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    global,
    storage
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    paths: ['storage']
  })]
})

export default store
