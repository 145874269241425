
const state = {
    dialogLoginFlagevuex: false,
    dialogdetailFlagevuex: false,
    loadingvuex: false,
    screenDetailvuex: {},
    detailItemvuex: {},
    searchtextvuex: ''
}

const mutations = {
    CHANGE_GLOBAL_SETTING: (state, { key, value }) => {
        // eslint-disable-next-line no-prototype-builtins
        if (state.hasOwnProperty(key)) {
            state[key] = value
        }
    },
}

export default {
    namespaced: true,
    state,
    mutations
}
