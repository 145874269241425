import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/index.less' // global css
import Vant from 'vant';
import 'vant/lib/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Vant);
function genID(length) {
  return Number(
    Math.random().toString().substr(3, length) + Date.now()
  ).toString(36)
}
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
Vue.prototype.$isMobile = isMobile;

if (isMobile) {
  (function (doc, win) {
    var docEl = doc.documentElement,
      resizeEvt = "orientationchange" in window ? "orientationchange" : "resize",
      recalc = function () {
        var clientWidth = docEl.clientWidth;

        if (!clientWidth) return;

        docEl.style.fontSize = (clientWidth / 375) + "px";
      };

    if (!doc.addEventListener) return;

    win.addEventListener(resizeEvt, recalc, false);

    doc.addEventListener("DOMContentLoaded", recalc, false);
  })(document, window);

} else {
  console.log('当前在PC端');
}


// 随机生成id
Vue.prototype.$genID = genID
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
